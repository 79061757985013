import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../../components/layouts/secondary-landing";
import SEO from "../../../../components/seo/seo";
import HeroChevron from "../../../../components/hero/hero-chevron";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import CoverdellEducationSavings from "../../../../components/coverdell-education-savings/coverdell-education-savings";
import MinorSavingsOverview from "../../../../components/savings-overview/minor-savings-overview";
import FaqAccordion from "../../../../components/faq/faq-accordion";
import StretchedLinkCards from "../../../../components/card/stretched-link-cards";

// Helpers
import getHeroImgVariables from "../../../../helpers/getHeroImgVariables";
import getSortedFaqDataByCategory from "../../../../helpers/getSortedFaqDataByCategory";

const YouthSavingsAccount = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/personal-banking/savings-account/youth-savings-account/hero-kid-teen-savings-062424-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/personal-banking/savings-account/youth-savings-account/hero-kid-teen-savings-062424-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/personal-banking/savings-account/youth-savings-account/hero-kid-teen-savings-062424-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/personal-banking/savings-account/youth-savings-account/hero-kid-teen-savings-062424-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/personal-banking/savings-account/youth-savings-account/hero-kid-teen-savings-062424-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/personal-banking/savings-account/youth-savings-account/hero-kid-teen-savings-062424-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/personal-banking/savings-account/youth-savings-account/hero-kid-teen-savings-062424-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      cdCardImage: file(relativePath: { eq: "thumbnail-kid-and-teen-cd-040423.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      greenLightCardImage: file(relativePath: { eq: "thumbnail-greenlight-040423.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      saveAtSchoolCardImage: file(relativePath: { eq: "thumbnail-save-at-school-040423.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const heroChevronData = {
    id: "savings-account-hero",
    ...getHeroImgVariables(imgData),
    altText: "Father flying son on his back.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Start Your Child or Teen on the Right Financial Path",
            class: "text-white"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/savings-account",
      title: "Savings, CDs & Money Markets"
    },
    {
      id: 3,
      active: true,
      title: "Youth Savings Account"
    }
  ];

  const { sortedFaqs, categoryFootnotes, schema } = getSortedFaqDataByCategory(["Youth Savings"]);

  const title = "Youth Savings Account - Savings for Kids and Teens",
    description =
      "Help prepare your kids and teenagers for the future with youth savings account from WaFd Bank. Easily transfer money from your account to theirs.";
  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/savings-account/youth-savings-account"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-kid-teen-savings-062424.jpg"
      }
    ],
    schema
  };

  const cardsData = {
    cards: [
      {
        id: "cd-card",
        url: "/locations",
        title: "Certificate of Deposit (CD) for kids and teens",
        text: "Only $500 initial deposit. Terms from 3 months to 5 years. Monthly interest payments available for accounts with $10,000 or more. <a href='/locations' class='text-decoration-none'>Open at a branch</a>",
        image: {
          imgVariable: imgData.cdCardImage.childImageSharp.gatsbyImageData,
          altText: "A mother and young son laughing while doing homework."
        }
      },
      {
        id: "greenlight-card",
        url: "/personal-banking/greenlight-kids-debit-card",
        title: "Set your kids up for financial success with Greenlight!",
        text: "They'll learn the value of a dollar, play fun games to learn money skills, understand smart financial habits, and much more.",
        image: {
          imgVariable: imgData.greenLightCardImage.childImageSharp.gatsbyImageData,
          altText: "Mother and daughter smiling while looking at the green light app and credit card."
        }
      },
      {
        id: "save-at-school-program-card",
        url: "/personal-banking/savings-account/youth-savings-account/save-at-school",
        title: "WaFd Save at School Program",
        text: "Partner with WaFd Bank to help teach our kids how to save money with a Save at School program!",
        image: {
          imgVariable: imgData.saveAtSchoolCardImage.childImageSharp.gatsbyImageData,
          altText: "Happy school kids celebrating"
        }
      }
    ]
  };

  const faqAccordionData = {
    id: "youth-savings-faq-sections",
    title: "Kid and Teen Savings Account FAQs",
    // pass faqData instead of faqCategoryNames
    faqData: {
      sortedFaqs,
      categoryFootnotes
    }
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Kid and Teen Savings Accounts</h1>
        <h3>
          WaFd Bank's secure savings accounts for our youngest savers come with tools you can use to teach smart money
          management. Easily transfer money from your account to theirs for allowances or saving for the future.
          Withdraw or deposit cash and checks at select <Link to="/locations/atm">ATMs</Link>.
        </h3>
      </section>
      <MinorSavingsOverview />
      <StretchedLinkCards {...cardsData} />
      <CoverdellEducationSavings />
      <section id="open-kid-savings-account" className="container">
        <h2 className="text-center">To open an account you'll need:</h2>
        <div className="table-responsive d-none d-sm-block">
          <table id="youth-savings-open-account-table" className="table table-bordered">
            <tbody>
              <tr>
                <td className="p-0" width="33.3%">
                  <StaticImage
                    src="../../../../images/playing-toddler-savings-account.jpg"
                    alt="Child savings account"
                    placeholder="blurred"
                    quality="100"
                    className="border-radius-top-0 border-radius-bottom-0"
                  />
                </td>
                <td className="p-0" width="33.3%">
                  <StaticImage
                    src="../../../../images/mother-daughter-money-market.jpg"
                    alt="Kid savings account"
                    placeholder="blurred"
                    quality="100"
                    className="border-radius-top-0 border-radius-bottom-0"
                  />
                </td>
                <td className="p-0" width="33.3%">
                  <StaticImage
                    src="../../../../images/teenage-girl-gold-money-market.jpg"
                    alt="Teen savings account"
                    placeholder="blurred"
                    quality="100"
                    className="border-radius-top-0 border-radius-bottom-0"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="text-center">
                    <strong>Under 5</strong>
                  </p>
                </td>
                <td>
                  <p className="text-center">
                    <strong>5-12</strong>
                  </p>
                </td>
                <td>
                  <p className="text-center">
                    <strong>13-17</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  <p>
                    Set up as a Uniform Transfer Minor Account
                    <br />
                    (UTMA)
                    <br />A parent or custodian may open the account on the child's behalf.
                  </p>
                </td>
                <td className="text-center">
                  <p>Minor and Parent/Legal Guardian open account together</p>
                </td>
                <td className="text-center">
                  <p>Minor and any adult 18+ years of age can open an account</p>
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  <strong>Child's Identification</strong>
                  <br />
                  One of the below:
                  <div className="d-flex justify-content-center text-left">
                    - State Issued ID
                    <br />
                    - Passport (US or Foreign)
                    <br />- Green Card
                  </div>
                  <div className="d-flex flex-row align-items-center mb-2 w-75 mx-auto">
                    <div className="border-bottom-2 flex-grow-1"></div>
                    <div className={`d-flex justify-content-center align-items-center`}>
                      <span className="text-secondary mx-3">OR</span>
                    </div>
                    <div className="border-bottom-2 flex-grow-1"></div>
                  </div>
                  <strong>Both of the below:</strong>
                  <div className="d-flex justify-content-center text-left">
                    - Birth Certificate
                    <br /> - Social Security Card
                  </div>
                </td>
                <td className="text-center">
                  <strong>Child's Identification</strong>
                  <br />
                  One of the below:
                  <div className="d-flex justify-content-center text-left">
                    - State Issued ID
                    <br />
                    - Passport (US or Foreign)
                    <br />- Green Card
                  </div>
                  <div className="d-flex flex-row align-items-center mb-2 w-75 mx-auto">
                    <div className="border-bottom-2 flex-grow-1"></div>
                    <div className={`d-flex justify-content-center align-items-center`}>
                      <span className="text-secondary mx-3">OR</span>
                    </div>
                    <div className="border-bottom-2 flex-grow-1"></div>
                  </div>
                  <strong>Two of the below:</strong>
                  <div className="d-flex justify-content-center text-left">
                    - Birth Certificate
                    <br /> - Social Security Card
                    <br />- Student ID
                  </div>
                </td>
                <td className="text-center">
                  <strong>Teen's Identification</strong>
                  <br />
                  One of the below:
                  <div className="d-flex justify-content-center text-left">
                    - State Issued ID
                    <br />
                    - State Driver's License or Permit
                    <br /> - Passport (US or Foreign)
                    <br />- Green Card
                  </div>
                  <div className="d-flex flex-row align-items-center mb-2 w-75 mx-auto">
                    <div className="border-bottom-2 flex-grow-1"></div>
                    <div className={`d-flex justify-content-center align-items-center`}>
                      <span className="text-secondary mx-3">OR</span>
                    </div>
                    <div className="border-bottom-2 flex-grow-1"></div>
                  </div>
                  <strong>Two of the below:</strong>
                  <div className="d-flex justify-content-center text-left">
                    - Birth Certificate
                    <br /> - Social Security Card
                    <br />- Student ID
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="text-center">
                    <strong>Adult</strong>
                  </p>
                  <p>
                    Must be at least 18 years old, and a US Citizen or Permanent Legal US Resident and reside in the
                    states of AZ, ID, NV, NM, OR, TX, UT, or WA.
                  </p>
                  <p>
                    Personal information, including address, phone number, email address and Social Security Number.
                  </p>
                  <p>Picture ID, such as a driver's license, state ID, Passport or military ID.</p>
                </td>
                <td>
                  <p className="text-center">
                    <strong>Adult</strong>
                  </p>
                  <p>Must be child's Parent/Legal Guardian.</p>
                  <p>
                    Must be at least 18 years old, and a US Citizen or Permanent Legal US Resident and reside in the
                    states of AZ, CA, ID, NV, NM, OR, TX, UT, or WA.
                  </p>
                  <p>
                    Personal information, including address, phone number, email address and Social Security Number.
                  </p>
                  <p>Picture ID, such as a driver's license, state ID, Passport or military ID.</p>
                </td>
                <td>
                  <p className="text-center">
                    <strong>Adult</strong>
                  </p>
                  <p>
                    Must be at least 18 years old, and a US Citizen or Permanent Legal US Resident and reside in the
                    states of AZ, CA, ID, NV, NM, OR, TX, UT, or WA.
                  </p>
                  <p>
                    Personal information, including address, phone number, email address and Social Security Number.
                  </p>
                  <p>Picture ID, such as a driver's license, state ID, Passport or military ID.</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="d-sm-none">
          <div id="m-child-savings-account">
            <p className="text-center">
              <strong>Under 5</strong>
            </p>
            <StaticImage
              src="../../../../images/playing-toddler-savings-account.jpg"
              alt="Child savings account"
              placeholder="blurred"
              className="mb-3"
              quality="100"
            />
            <p>Set up as Uniform Transfer Minor Account (UTMA) Custodian on the Child's behalf</p>
            <div className="text-center">
              <strong>
                Child's Identification
                <br />
                One of the below:
                <br />
              </strong>
              <div className="d-flex justify-content-center text-left">
                - State Issued ID
                <br />
                - Passport (US or Foreign)
                <br />- Green Card
              </div>
            </div>
            <div className="d-flex flex-row align-items-center mb-2 w-75 mx-auto">
              <div className="border-bottom-2 flex-grow-1"></div>
              <div className={`d-flex justify-content-center align-items-center`}>
                <span className="text-secondary mx-3">OR</span>
              </div>
              <div className="border-bottom-2 flex-grow-1"></div>
            </div>
            <div className="text-center">
              <strong>Both of the below:</strong>
              <div className="d-flex justify-content-center text-left">
                - Birth Certificate
                <br /> - Social Security Card
              </div>
            </div>
            <p>
              <strong>Adult</strong>
            </p>
            <p>
              Must be at least 18 years old, and a US Citizen or Permanent Legal US Resident and reside in the states of
              AZ, ID, NV, NM, OR, TX, UT, or WA.
            </p>
            <p>Personal information, including address, phone number, email address and Social Security Number.</p>
            <p>Picture ID, such as a driver's license, state ID, Passport or military ID.</p>
          </div>
          <hr />
          <div id="m-kid-savings-account">
            <p className="text-center">
              <strong>5-12</strong>
            </p>
            <StaticImage
              src="../../../../images/mother-daughter-money-market.jpg"
              alt="Kid savings account"
              placeholder="blurred"
              className="mb-3"
              quality="100"
            />
            <p>Minor and Parent/Legal Guardian open account together</p>
            <div className="text-center">
              <strong>
                Child's Identification
                <br />
                One of the below:
                <br />
              </strong>
              <div className="d-flex justify-content-center text-left">
                - State Issued ID
                <br />
                - Passport (US or Foreign)
                <br />- Green Card
              </div>
            </div>
            <div className="d-flex flex-row align-items-center mb-2 w-75 mx-auto">
              <div className="border-bottom-2 flex-grow-1"></div>
              <div className={`d-flex justify-content-center align-items-center`}>
                <span className="text-secondary mx-3">OR</span>
              </div>
              <div className="border-bottom-2 flex-grow-1"></div>
            </div>
            <div className="text-center">
              <strong>Two of the below:</strong>
              <div className="d-flex justify-content-center text-left">
                - Birth Certificate
                <br /> - Social Security Card
                <br />- Student ID
              </div>
            </div>
            <p>
              <strong>Adult</strong>
            </p>
            <p>Must be child's Parent/Legal Guardian.</p>
            <p>
              Must be at least 18 years old, and a US Citizen or Permanent Legal US Resident and reside in the states of
              AZ, CA, ID, NV, NM, OR, TX, UT, or WA.
            </p>
            <p>Personal information, including address, phone number, email address and Social Security Number.</p>
            <p>Picture ID, such as a driver's license, state ID, Passport or military ID.</p>
          </div>
          <hr />
          <div id="m-teen-savings-account">
            <p className="text-center">
              <strong>13-17</strong>
            </p>
            <StaticImage
              src="../../../../images/teenage-girl-gold-money-market.jpg"
              alt="Teen savings account"
              placeholder="blurred"
              className="mb-3"
              quality="100"
            />
            <p>Minor and any adult 18+ years of age can open an account</p>
            <div className="text-center">
              <strong>
                Teen's Identification
                <br />
                One of the below:
                <br />
              </strong>
              <div className="d-flex justify-content-center text-left">
                - State Issued ID
                <br />- State Driver's License or Permit
                <br />- Passport (US or Foreign)
                <br />- Green Card
              </div>
            </div>
            <div className="d-flex flex-row align-items-center mb-2 w-75 mx-auto">
              <div className="border-bottom-2 flex-grow-1"></div>
              <div className={`d-flex justify-content-center align-items-center`}>
                <span className="text-secondary mx-3">OR</span>
              </div>
              <div className="border-bottom-2 flex-grow-1"></div>
            </div>
            <div className="text-center">
              <strong>Two of the below:</strong>
              <div className="d-flex justify-content-center text-left">
                - Birth Certificate
                <br /> - Social Security Card
                <br />- Student ID
              </div>
            </div>
            <p>
              <strong>Adult</strong>
            </p>
            <p>
              Must be at least 18 years old, and a US Citizen or Permanent Legal US Resident and reside in the states of
              AZ, CA, ID, NV, NM, OR, TX, UT, or WA.
            </p>
            <p>Personal information, including address, phone number, email address and Social Security Number.</p>
            <p>Picture ID, such as a driver's license, state ID, Passport or military ID.</p>
          </div>
        </div>
      </section>
      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default YouthSavingsAccount;
